import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import AcceptInvitation from './Accept';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="match">
          <Route path=":matchId" element={<App/>}/>
        </Route>
        <Route path="accept">
            <Route path=":matchId" element={<AcceptInvitation/>}/>
          </Route>
        <Route
          path="*"
          element={ 
            <main style={{ padding: "1rem" }}>
              <p>404 mon khey, y a rien à voir ici</p>
            </main>
          }
        />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
