import './App.css';
import React from 'react';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { doc, getDoc } from "firebase/firestore"; 
import { Link } from "react-router-dom";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
import { useParams } from "react-router-dom";

function App(props) {
  let params = useParams();
  return (
    <div className="App">
      <header className="App-header">
      <h1>⚽️ Five</h1>
      <Match
        matchId={params.matchId}
      ></Match>
      </header>
    </div>
  );
}


export default App;

class Match extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      match: null,
      error: null
    }
  }

  componentDidMount() {
    this.fetchMatches(this.props.matchId);
  }

  fetchMatches = async (matchId) => {
    const firebaseConfig = {
      apiKey: "AIzaSyD1LWlL7xguVXP6eXfJOD4kEqP1tmO_Kcg",
      authDomain: "five-46786.firebaseapp.com",
      projectId: "five-46786",
      storageBucket: "five-46786.appspot.com",
      messagingSenderId: "996007093810",
      appId: "1:996007093810:web:4394a6190560ca248bfe2b",
      measurementId: "G-6KZM7MCPVY"
    };

    const app = initializeApp(firebaseConfig);
    // Initialize Cloud Firestore and get a reference to the service
    const db = getFirestore(app);

    const citiesRef = doc(db, "matches", matchId);

    const docSnap = await getDoc(citiesRef);

    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
      this.setState({
        match : docSnap.data(),
        isLoading: false
      })
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!" + matchId);

      this.setState({
        error : "Impossible de charger le match",
        isLoading: false
      })
    }
  }
  

  render() {
    if(this.state.isLoading) {
      return <h4>Chargement</h4>
    } else if(this.state.error !== null) {
      return <h4>Erreur lors du chargement</h4>
    }
    return (
      <div>
        {this.state.isLoading}
        <Invitation
          matchId={this.props.matchId}
          date={this.state.match.date.toDate().toLocaleString()}
          infos={this.state.match.infos}
          price={this.state.match.price/this.state.match.numberOfPlayers}
          players={this.state.match.players}
          numberOfPlayers={this.state.match.numberOfPlayers}
        />
      </div>
    );
  }
}

class Invitation extends React.Component {
  constructor(props) {
    super(props);
  }
  
  render() {
    return (
      <div>
        <h4> 📆 Invitation</h4>
        <p>T'es chaud pour un match le <strong>{this.props.date}</strong></p>

        <h5>ℹ️ Infos supplémentaires :</h5>
        <p>{this.props.infos}</p>

        <h5>💰 Prix :</h5>
        <p>{this.props.price}€</p>
        
        <h5>🏃‍♂️ Joueurs : {this.props.players?.length ?? 0}/{this.props.numberOfPlayers}</h5>
        { this.props.players && 
          <ul style={{ columns: 2 }}>   
            {
              this.props.players.map((item, index) => (
                <li key={index} >{this.props.players[index]}</li>
              ))
            }
          </ul>
        }

        { (this.props.players?.length ?? 0) < this.props.numberOfPlayers &&
          
          <Link component="button" to={`/accept/${this.props.matchId}`}>
            <button>Je suis là</button>
          </Link>

        }

        { this.props.players && this.props.players.length >= this.props.numberOfPlayers &&
          
          <p>Équipes au complet</p>

        }
      </div>
    );
  }
}
