
import React from 'react';
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { updateDoc, doc, arrayUnion } from "firebase/firestore"; 
import { useParams } from "react-router-dom";


class AcceptInvitation extends React.Component {
    constructor(props) {
      super(props);
      this.state = {name: '', isPlayerAdded: localStorage.getItem(this.props.params.matchId) === 'true'};
  
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
    }
  
    handleChange(event) {
      this.setState({name: event.target.value});
    }
  
    handleSubmit(event) {
        this.addPlayer(this.props.params.matchId, this.state.name)
        event.preventDefault();
    }
  
    addPlayer = async (matchId, playerName) => {
        const firebaseConfig = {
            apiKey: "AIzaSyD1LWlL7xguVXP6eXfJOD4kEqP1tmO_Kcg",
            authDomain: "five-46786.firebaseapp.com",
            projectId: "five-46786",
            storageBucket: "five-46786.appspot.com",
            messagingSenderId: "996007093810",
            appId: "1:996007093810:web:4394a6190560ca248bfe2b",
            measurementId: "G-6KZM7MCPVY"
        };
        
        const app = initializeApp(firebaseConfig);
        // Initialize Cloud Firestore and get a reference to the service
        const db = getFirestore(app);
        
        const playersRef = doc(db, "matches", matchId);
        await updateDoc(playersRef, {
            players: arrayUnion(this.state.name)
        });
        localStorage.setItem(matchId, true);
        this.setState({
            isPlayerAdded: "true"
        })
    }
    render() {
        if(this.state.isPlayerAdded) {
            return (
                <div className="App">
                <header className="App-header">
                    <p>Tu as été ajouté à la liste de joueurs 🏃‍♂️</p>
                </header>
            </div>
            )
        } 
        return (
            <div className="App">
                <header className="App-header">
                <form onSubmit={this.handleSubmit}>
                    <label>
                    Nom :
                    <input type="text" value={this.state.value} onChange={this.handleChange} />
                    </label>
                    <br/>
                    <input type="submit" value="Envoyer" />
                </form>
                </header>
            </div>
        )
    }
  }

export default (props) => (
    <AcceptInvitation
        {...props}
        params={useParams()}
    />
);